import styled from 'styled-components';

// import { responsive } from 'ui';

export const Outer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
